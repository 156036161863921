import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/what-to-expect.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import SEO from "../components/seo"
import ModalVideo from "react-modal-video"

const Links = styled(Link)`
  color: #312e2e;
  font-weight: 600;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Our Mission and Vision"
    SecondText="#WeCare"
  >
    <SEO title="Our Mission and Vision" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Our Mission
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      You might have seen our mission across our site or any of our campuses:
      Cross Church cares about <strong>MAKING JESUS KNOWN</strong> by creating a
      movement of multiplying churches.
      <br />
      <br />
      It's that simple.
      <br />
      <br />
      That's what drives our mission.
      <br />
      <br />
      It starts with inviting you to our time of God, Honoring, Worship. Our
      morning worship services is the best time for first timers to come and
      check out what God is doing in and through our church. <br />
      <br />
      The second step is to connect with one of our Bible Groups. We believe
      that it is imperative that you get connected relationally with others in
      our church. <br />
      <br />
      The final part of our development plan is to employee you into service in
      and through the church. We say often that God does not call us to sit,
      soak, and sour, but to serve. We believe that fully devoted followers of
      Jesus are servants that use their gifts to serve others. Cross Church does
      not want to be a cruise ship where we all come to get our needs met.
      Instead we want to be an aircraft carrier where we prepare you and launch
      you into our world as servants of Christ!
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Our Vision
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Cross Church cares about <strong>MAKING JESUS KNOWN</strong> by creating a
      movement of multiplying churches.
      <br />
      <br />
      The <Links to="/phoenix">Phoenix Campus</Links> was launched in March of
      2016 as Cross Church’s second location and is the first campus to make us
      a multisite church.
      <br />
      <br />
      Jump on board with our vision of <strong>MAKING JESUS KNOWN</strong> by
      visiting a <Links to="/churchnearme">campus near you</Links> this weekend!
    </ParagraphText>
  </Layout>
)

export default IndexPage
